/* eslint react/destructuring-assignment: 0 */
import React from 'react';
import { Card, CardBody, Col } from 'reactstrap';
import DataTable from 'react-data-table-component';
import moment from 'moment';

const AttemptsList = ({ attempts }) => {
  const columns = [
    {
      name: 'Coordenadas',
      selector: (row) => row.coordinates,
    },
    {
      name: 'Utilizador',
      selector: (row) => row.user.name,
    },
    {
      name: 'Email',
      selector: (row) => row.user.email,
    },
    {
      name: 'Prémio',
      selector: (row) => (row.isWin ?
        <span className="text-success">Ganhou</span>
        :
        <span className="text-danger">Perdeu</span>),
    },
    {
      name: 'Data',
      selector: (row) => <p>{moment(row.created_at.date).format('DD-MM-YYYY')}
        <br />{moment(row.created_at.date).format('HH:mm:ss')}</p>,
    },
  ];

  return (
    <Col md={12}>
      <Card>
        <CardBody>
          <div className="card__title">
            <h5 className="bold-text">Lista de tentativas registadas</h5>
          </div>
          <DataTable
            columns={columns}
            data={attempts}
            pagination
          />
        </CardBody>
      </Card>
    </Col>
  );
};

export default AttemptsList;
