import React from 'react';
import {
  Col, Container, Row, Button,
} from 'reactstrap';
import { useHistory } from 'react-router-dom';
import PrizesList from './components/PrizesList';
import useFetch from '../../hooks/use-fetch';

const Prizes = () => {
  const history = useHistory();
  const {
    data,
    loading,
    error,
    refetch,
  } = useFetch('prize/list');
  return (
    <Container className="dashboard">
      <Row>
        <Col md={9}>
          <h3 className="page-title">Prémios</h3>
        </Col>
        <Col md={3}>
          <Button className="float-right btn-secondary" onClick={() => history.push('/new-prize')}>Novo prémio</Button>
        </Col>
      </Row>
      {data &&
        <Row>
          <PrizesList prizes={data.Prizes} refetch={refetch}/>
        </Row>
      }
    </Container>
  );
};

export default Prizes;
