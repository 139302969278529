import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import SidebarLink from './SidebarLink';
import SidebarCategory from './SidebarCategory';

const SidebarContent = ({ onClick }) => {
  const history = useHistory();

  const handleHideSidebar = () => {
    onClick();
  };

  const handleLogout = () => {
    localStorage.removeItem('accessToken');
    history.push('/log_in');
  };

  return (
    <div className="sidebar__content">
      {/* <ul className="sidebar__block">
        <SidebarLink title="Log Out" icon="exit" onClick={handleLogout} />
      </ul> */}
      <ul className="sidebar__block">
        <SidebarLink title="Tentativas" route="/tries" onClick={handleHideSidebar} />
        <SidebarLink title="Vitórias" route="/wins" onClick={handleHideSidebar} />
        <SidebarLink title="Prémios" route="/prizes" onClick={handleHideSidebar} />
      </ul>
    </div>
  );
};

SidebarContent.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default SidebarContent;
