/* eslint consistent-return: 0 */
import axios from 'axios';
import { toast } from 'react-toastify';
import history from '../history';

const axiosConfig = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

axiosConfig.interceptors.response.use((response) => {
  return response;
}, (error) => {
  if (error.response.status === 401) {
    toast.error('A sua sessão terminou, por favor faça login de novo', {
      toastId: '401',
    });
    localStorage.removeItem('accessToken');
    localStorage.removeItem('username');
    history.push('/log_in');
  } else {
    return Promise.reject(error);
  }
});

export default axiosConfig;
