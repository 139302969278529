import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import WinsList from './components/WinsList';
import useFetch from '../../hooks/use-fetch';

const Wins = () => {
  const { data, loading, error } = useFetch('wins/list');

  return (
    <Container className="dashboard">
      <Row>
        <Col md={12}>
          <h3 className="page-title">Vitórias</h3>
        </Col>
      </Row>
      {data && data.Wins &&
        <Row>
          <WinsList wins={data.Wins}/>
        </Row>
      }
    </Container>
  );
};

export default Wins;
