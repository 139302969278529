import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Button,
} from 'reactstrap';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import DotsSquareIcon from 'mdi-react/DotsSquareIcon';
import DiceMultipleIcon from 'mdi-react/DiceMultipleIcon';
import SealIcon from 'mdi-react/SealIcon';
import useSubmit from '../../../hooks/use-submit';

const PrizeForm = () => {
  const history = useHistory();

  const maxLine = 60;
  const maxColumn = 60;

  const {
    register, handleSubmit, formState: { errors }, setValue,
  } = useForm();

  const { /* isSubmitting, */ submitError, response, submit } = useSubmit(
    'prize/create',
    'post',
  );

  const genRandomCoordinates = () => {
    const min = 0;
    const line = Math.floor(Math.random() * (maxLine - min + 1) + min);
    const column = Math.floor(Math.random() * (maxColumn - min + 1) + min);

    setValue('line', line);
    setValue('column', column);
  };

  const handleCreatePrize = (data) => {
    submit({
      name: data.name,
      coordinates: `${data.line},${data.column}`,
    });
  };

  useEffect(() => {
    if (response !== null) {
      toast.success('Novo prémio criado!');
      history.push('/prizes');
    }
  }, [response]);

  useEffect(() => {
    if (submitError !== '') {
      toast.error('Ocorreu um erro');
    }
  }, [submitError]);

  return (
    <form className="form" onSubmit={handleSubmit(handleCreatePrize)}>
      <Row className="w-100 m-0">
        <Col md={12}>
          <div className="form__form-group">
            <span className="form__form-group-label">Nome</span>
            <div className="form__form-group-field">
              <div className="form__form-group-icon">
                <SealIcon />
              </div>
              <input {...register('name', { required: true })} />
            </div>
          </div>
        </Col>
      </Row>
      <Row className="w-100 m-0 align-items-end">
        <Col md={5}>
          <div className="form__form-group">
            <span className="form__form-group-label">Linha</span>
            <div className="form__form-group-field">
              <div className="form__form-group-icon">
                <DotsSquareIcon />
              </div>
              <input {...register('line', { required: true })} />
            </div>
          </div>
        </Col>
        <Col md={5}>
          <div className="form__form-group">
            <span className="form__form-group-label">Coluna</span>
            <div className="form__form-group-field">
              <div className="form__form-group-icon">
                <DotsSquareIcon />
              </div>
              <input {...register('column', { required: true })} />
            </div>
          </div>
        </Col>
        <Col md={2}>
          <Button className="w-100 icon" onClick={genRandomCoordinates}><DiceMultipleIcon />Aleatório</Button>
        </Col>
      </Row>

      <Row className="w-100 m-0">
        <Col md={12}>
          <input className="btn btn-primary text-white" type="submit" value="Submeter"/>
        </Col>
      </Row>

    </form>
  );
};

export default PrizeForm;
