import React from 'react';
import {
  Input,
  Row,
  Col,
  Label,
} from 'reactstrap';

const WinsListFilters = ({ setCodeFilter }) => {
  return (
    <Row className="mb-1">
      <Col md={3}>
        <Label for="code">Filtrar por código</Label>
        <Input type="text" id="code" onChange={(e) => setCodeFilter(e.target.value)}/>
      </Col>
    </Row>
  );
};

export default WinsListFilters;