import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import AttemptsList from './components/AttemptsList';
import useFetch from '../../hooks/use-fetch';

const Entries = () => {
  const { data, loading, error } = useFetch('attempt/list');
  return (
    <Container className="dashboard">
      <Row>
        <Col md={12}>
          <h3 className="page-title">Tentativas</h3>
        </Col>
      </Row>
      {data &&
        <Row>
          <AttemptsList attempts={data.Attempts}/>
        </Row>
      }
    </Container>
  );
};

export default Entries;
