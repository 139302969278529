/* eslint react/destructuring-assignment: 0 */
import React from 'react';
import {
  Card,
  CardBody,
  Col,
} from 'reactstrap';
import DataTable from 'react-data-table-component';
import DeletePrizeModal from './DeletePrizeModal';

const PrizesList = ({ prizes, refetch }) => {
  const columns = [
    {
      name: 'Coordenadas',
      selector: (row) => row.coordinates,
    },
    {
      name: 'Nome',
      selector: (row) => row.name,
    },
    {
      name: 'Estado',
      selector: (row) => (row.assigned ?
        <span className="text-danger">Encontrado</span>
        :
        <span className="text-success">Disponível</span>),
    },
    {
      name: 'Opções',
      selector: (row) => <DeletePrizeModal buttonLabel="Apagar" id={row.id} refetch={refetch}/>,
    },
  ];

  return (
    <Col md={12}>
      <Card>
        <CardBody>
          <div className="card__title">
            <h5 className="bold-text">Lista de prémios</h5>
          </div>
          <DataTable
            columns={columns}
            data={prizes}
            pagination
          />
        </CardBody>
      </Card>
    </Col>
  );
};

export default PrizesList;
