import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import Layout from '../Layout/index';
import MainWrapper from './MainWrapper';

import LogIn from '../LogIn/index';
import Entries from '../Entries/index';
import Wins from '../Wins/index';
import Prizes from '../Prizes/index';
import CreatePrize from '../Prizes/create';

const ProtectedRoute = ({ path, component }) => {
  const isAuthenticated = localStorage.getItem('accessToken');

  return (
    <>
      {isAuthenticated
        ?
          <Route path={path} component={component} />
        :
          <Redirect to="/log_in" />}
    </>
  );
};

const wrappedRoutes = () => (
  <div>
    <Layout />
    <div className="container__wrap">
      <ProtectedRoute path="/tries" component={Entries} />
      <ProtectedRoute path="/wins" component={Wins} />
      <ProtectedRoute path="/prizes" component={Prizes} />
      <ProtectedRoute path="/new-prize" component={CreatePrize} />
    </div>
  </div>
);

const Router = () => (
  <MainWrapper>
    <main>
      <Switch>
        <Route exact path="/" component={LogIn} />
        <Route exact path="/log_in" component={LogIn} />
        <Route path="/" component={wrappedRoutes} />
      </Switch>
    </main>
  </MainWrapper>
);

export default Router;
