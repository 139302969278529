import React from 'react';
import {
  Button,
} from 'reactstrap';

const TopbarMenuLinks = ({ title, icon, onClick }) => (
  <Button className="topbar__link border-0 bg-transparent icon m-0 p-2" onClick={onClick}>
    <span className={`topbar__link-icon lnr lnr-${icon}`} />
    <p className="topbar__link-title">{title}</p>
  </Button>
);

export default TopbarMenuLinks;
