import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import EyeIcon from 'mdi-react/EyeIcon';
import KeyVariantIcon from 'mdi-react/KeyVariantIcon';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import useSubmit from '../../../hooks/use-submit';
import CheckBox from '../../../shared/components/form/CheckBox';

const LogInForm = () => {
  const [isPasswordShown, setIsPasswordShown] = useState(false);
  const [isToggleCheckboxEnabled, setIsToggleCheckboxEnabled] = useState(false);

  const history = useHistory();

  const {
    register, handleSubmit, formState: { errors },
  } = useForm();

  const { /* isSubmitting, submitError, */ response, submit } = useSubmit(
    'auth/login',
    'post',
  );

  console.log(response);

  const handleShowPassword = () => {
    setIsPasswordShown(!isPasswordShown);
  };

  const handleToggleCheckBox = () => {
    setIsToggleCheckboxEnabled(!isToggleCheckboxEnabled);
  };

  const handleLogin = (data) => {
    submit({
      email: data.email,
      password: data.password,
    });
  };

  useEffect(() => {
    if (response?.Token?.token) {
      localStorage.setItem('username', response?.AdminUser?.email);
      localStorage.setItem('accessToken', response?.Token?.token);
      history.push('/prizes');
    }
  }, [response]);

  return (
    <form className="form" onSubmit={handleSubmit(handleLogin)}>
      <div className="form__form-group">
        <span className="form__form-group-label">Username</span>
        <div className="form__form-group-field">
          <div className="form__form-group-icon">
            <AccountOutlineIcon />
          </div>
          <input {...register('email', { required: true })} />
        </div>
      </div>
      <div className="form__form-group">
        <span className="form__form-group-label">Password</span>
        <div className="form__form-group-field">
          <div className="form__form-group-icon">
            <KeyVariantIcon />
          </div>
          <input
            type={isPasswordShown ? 'text' : 'password'}
            {...register('password', { required: true })}
          />
          <button
            className={`form__form-group-button${isPasswordShown ? ' active' : ''}`}
            onClick={() => handleShowPassword()}
            type="button"
          ><EyeIcon />
          </button>
        </div>
        <div className="account__forgot-password">
          <a href="/">Forgot a password?</a>
        </div>
      </div>
      <div className="form__form-group">
        <div className="form__form-group-field">
          <CheckBox
            name="remember_me"
            label="Remember me"
            value={isToggleCheckboxEnabled}
            onChange={handleToggleCheckBox}
          />
        </div>
      </div>
      <input className="btn btn-primary account__btn account__btn--small" type="submit" />
    </form>
  );
};

export default LogInForm;
