import { useState, useEffect } from 'react';
import api from '../utils/api';

const useFetch = (url, auth = true) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [data, setData] = useState([]);

  if (auth) {
    api.defaults.headers.common = { 'x-api-key': localStorage.getItem('accessToken') };
  }

  const fetchData = () => {
    setLoading(true);
    api
      .get(url)
      .then((response) => {
        console.log(response);
        if (response?.data?.ResultCode === 1) {
          setData(response?.data?.Data);
          setError('');
        } else if (response?.data?.Result === 'Error') {
          setError(response?.data?.Error);
        } else {
          setError('Unknown error');
        }
      })
      .catch((err) => {
        console.log(err);
        setError(err.response);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const refetch = () => fetchData();

  useEffect(() => {
    if (!url) return;
    fetchData();
  }, [url]);

  return {
    data,
    loading,
    error,
    refetch,
  };
};

export default useFetch;