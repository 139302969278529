import { useState, useEffect } from 'react';
import api from '../utils/api';

const useSubmit = (url, method, auth = true) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitError, setError] = useState('');
  const [response, setResponse] = useState(null);

  if (auth) {
    api.defaults.headers.common = { 'x-api-key': localStorage.getItem('accessToken') };
  }

  const submit = (body) => {
    setIsSubmitting(true);
    api[method](url, body)
      .then((resp) => {
        console.log(resp);
        if (resp?.data?.ResultCode === 1) {
          setResponse(resp?.data?.Data);
          setError('');
        } else if (resp?.data?.Result === 'Error') {
          setError(resp?.data?.Error);
          setResponse(null);
        } else {
          setError('Unknown error');
          setResponse(null);
        }
      })
      .catch((err) => {
        console.log(err.response);
        setError(err.response);
        setResponse(null);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  return {
    response, isSubmitting, submitError, submit,
  };
};

export default useSubmit;
