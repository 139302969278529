/* eslint react/no-multi-comp: 0, react/prop-types: 0 */

import React, { useState, useEffect } from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import { toast } from 'react-toastify';
import DeleteForeverIcon from 'mdi-react/DeleteForeverIcon';
import useSubmit from '../../../hooks/use-submit';

const DeletePrizeModal = (props) => {
  const {
    buttonLabel,
    className,
    id,
    refetch,
  } = props;

  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  const { /* isSubmitting, */ submitError, response, submit } = useSubmit(
    `prize/delete/${id}`,
    'delete',
  );

  const onDelete = () => {
    submit(null);
  };

  useEffect(() => {
    if (response !== null) {
      toast.success('Prémio apagado!');
      refetch();
    }
  }, [response]);

  useEffect(() => {
    if (submitError !== '') {
      if (submitError === 'Cant delete already assigned prize') {
        toast.error('Não pode apagar um prémio que já foi atribuído!');
      } else {
        toast.error('Ocorreu um erro');
      }
      toggle();
    }
  }, [submitError]);

  return (
    <div>
      <DeleteForeverIcon role="button" onClick={toggle}/>
      <Modal isOpen={modal} centered toggle={toggle} className={className}>
        <ModalHeader toggle={toggle}><strong>Remover prémio</strong></ModalHeader>
        <ModalBody>
          De certeza que pretende remover este prémio?
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={onDelete}>Apagar</Button>{' '}
          <Button color="secondary" onClick={toggle}>Cancelar</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default DeletePrizeModal;