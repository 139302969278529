import React from 'react';
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
} from 'reactstrap';
import PrizeForm from './components/PrizeForm';

const CreatePrize = () => {
  return (
    <Container className="dashboard">
      <Row>
        <Col md={9}>
          <h3 className="page-title">Novo prémio</h3>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Card>
            <CardBody>
              <div className="card__title">
                <h5 className="bold-text">Criar novo prémio</h5>
                <h5 className="subhead">Insira os detalhes</h5>
              </div>
              <PrizeForm />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default CreatePrize;