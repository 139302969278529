/* eslint react/destructuring-assignment: 0 */
import React, { useState } from 'react';
import { Card, CardBody, Col } from 'reactstrap';
import DataTable from 'react-data-table-component';
import moment from 'moment';
import WinsListFilters from './WinsListFilters';

const WinsList = ({ wins }) => {
  const [codeFilter, setCodeFilter] = useState('');

  const columns = [
    {
      name: 'Coordenadas',
      selector: (row) => row.prize.coordinates,
    },
    {
      name: 'Vencedor',
      selector: (row) => row.user.name,
    },
    {
      name: 'Tipo',
      selector: (row) => row.type,
    },
    {
      name: 'Email',
      selector: (row) => row.user.email,
    },
    {
      name: 'Prémio',
      selector: (row) => row.prize.name,
    },
    {
      name: 'Data',
      selector: (row) => <p>{moment(row.attempt.created_at.date).format('DD-MM-YYYY')}
        <br />{moment(row.attempt.created_at.date).format('HH:mm:ss')}</p>,
    },
    {
      name: 'Código',
      selector: (row) => row.code,
    },
  ];

  const filteredData = wins.filter(
    (item) => item.code && item.code.toLowerCase().includes(codeFilter.toLowerCase()),
  );

  return (
    <Col md={12}>
      <Card>
        <CardBody>
          <div className="card__title">
            <h5 className="bold-text">Lista de vitórias registadas</h5>
          </div>
          <WinsListFilters setCodeFilter={setCodeFilter} />
          <DataTable
            columns={columns}
            data={filteredData}
            pagination
          />
        </CardBody>
      </Card>
    </Col>
  );
};

export default WinsList;
