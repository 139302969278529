/* eslint react/jsx-boolean-value: 0 */
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import {
  Row,
  Col,
  CustomInput,
} from 'reactstrap';
import useFetch from '../../../hooks/use-fetch';
import useSubmit from '../../../hooks/use-submit';

const GiveawayToggle = () => {
  const giveawayId = 1;
  const [status, setStatus] = useState(false);

  const {
    data,
    loading,
    error,
    refetch,
  } = useFetch(`giveaway/details/${giveawayId}`);

  const {
    isSubmitting,
    submitError,
    response,
    submit,
  } = useSubmit(
    'giveaway/toggle',
    'post',
  );

  const toggleGiveaway = (e) => {
    setStatus(e.target.checked);
    submit({
      id: giveawayId,
      status: e.target.checked,
    });
  };

  useEffect(() => {
    if (data !== []) {
      setStatus(data?.isActive);
    }
  }, [data]);

  useEffect(() => {
    if (error !== '') {
      toast.error('Não foi possível consultar o estado da Giveaway no servidor :(');
    }
  }, [error]);

  useEffect(() => {
    if (response !== null) {
      if (response?.isActive === true) {
        toast.success('Giveaway ativada!');
      } else if (response?.isActive === false) {
        toast.success('Giveaway terminada!');
      }
      refetch();
    }
  }, [response]);

  useEffect(() => {
    if (submitError !== '') {
      toast.error('Ocorreu um erro');
      refetch();
    }
  }, [submitError]);

  return (
    <Row>
      <Col md={12} className="d-flex align-items-center justify-content-center mt-3">
        {data !== [] &&
          <CustomInput
            type="switch"
            id="toggle"
            name="toggle"
            label="Ativar Giveaway"
            checked={status}
            onChange={(e) => toggleGiveaway(e)}
            disabled={isSubmitting}
          />
        }
      </Col>
    </Row>
  );
};

export default GiveawayToggle;